var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    { staticClass: "grid grid-cols-10 mt-8 bg-white rounded shadow" },
    [
      _c(
        "div",
        {
          staticClass:
            "grid grid-cols-1 col-span-10 px-4 py-5 lg:col-span-7 xl:col-span-8 sm:p-6"
        },
        [
          _c("div", { staticClass: "flex justify-between mt-1 mb-6" }, [
            _c(
              "h6",
              { staticClass: "flex items-center sw-section-title" },
              [
                _c("chart-square-bar-icon", {
                  staticClass: "h-5 text-primary-400"
                }),
                _vm._v(
                  _vm._s(_vm.$t("dashboard.monthly_chart.title")) + "\n      "
                )
              ],
              1
            ),
            _vm._v(" "),
            _c(
              "div",
              { staticClass: "w-40 h-10", staticStyle: { "z-index": "0" } },
              [
                _c("sw-select", {
                  attrs: {
                    options: _vm.years,
                    "allow-empty": false,
                    "show-labels": false,
                    placeholder: _vm.$t("dashboard.select_year")
                  },
                  model: {
                    value: _vm.selectedYear,
                    callback: function($$v) {
                      _vm.selectedYear = $$v
                    },
                    expression: "selectedYear"
                  }
                })
              ],
              1
            )
          ]),
          _vm._v(" "),
          _vm.isLoaded
            ? _c("line-chart", {
                staticClass: "sm:w-full",
                attrs: {
                  "format-money": _vm.$utils.formatMoney,
                  "format-graph-money": _vm.$utils.formatGraphMoney,
                  invoices: _vm.getChartInvoices,
                  expenses: _vm.getChartExpenses,
                  receipts: _vm.getReceiptTotals,
                  income: _vm.getNetProfits,
                  labels: _vm.getChartMonths
                }
              })
            : _vm._e()
        ],
        1
      ),
      _vm._v(" "),
      _c(
        "div",
        {
          staticClass:
            "grid grid-cols-1 grid-cols-3 col-span-10 text-center border-t border-l border-gray-200 border-solid lg:border-t-0 lg:text-right lg:col-span-3 xl:col-span-2 lg:grid-cols-1"
        },
        [
          _c("div", { staticClass: "p-6" }, [
            _c("span", { staticClass: "text-xs leading-5 lg:text-sm" }, [
              _vm._v(
                "\n        " +
                  _vm._s(_vm.$t("dashboard.chart_info.total_sales")) +
                  "\n      "
              )
            ]),
            _vm._v(" "),
            _c("br"),
            _vm._v(" "),
            _vm.isLoaded
              ? _c(
                  "span",
                  {
                    staticClass:
                      "block mt-1 text-xl font-semibold leading-8 lg:text-2xl"
                  },
                  [
                    _c("div", {
                      domProps: {
                        innerHTML: _vm._s(
                          _vm.$utils.formatMoney(
                            _vm.getTotalSales,
                            _vm.defaultCurrency
                          )
                        )
                      }
                    })
                  ]
                )
              : _vm._e()
          ]),
          _vm._v(" "),
          _c("div", { staticClass: "p-6" }, [
            _c("span", { staticClass: "text-xs leading-5 lg:text-sm" }, [
              _vm._v(
                "\n        " +
                  _vm._s(_vm.$t("dashboard.chart_info.total_receipts")) +
                  "\n      "
              )
            ]),
            _vm._v(" "),
            _c("br"),
            _vm._v(" "),
            _vm.isLoaded
              ? _c(
                  "span",
                  {
                    staticClass:
                      "block mt-1 text-xl font-semibold leading-8 lg:text-2xl",
                    staticStyle: { color: "#00c99c" }
                  },
                  [
                    _c("div", {
                      domProps: {
                        innerHTML: _vm._s(
                          _vm.$utils.formatMoney(
                            _vm.getTotalReceipts,
                            _vm.defaultCurrency
                          )
                        )
                      }
                    })
                  ]
                )
              : _vm._e()
          ]),
          _vm._v(" "),
          _c("div", { staticClass: "p-6" }, [
            _c("span", { staticClass: "text-xs leading-5 lg:text-sm" }, [
              _vm._v(
                "\n        " +
                  _vm._s(_vm.$t("dashboard.chart_info.total_expense")) +
                  "\n      "
              )
            ]),
            _vm._v(" "),
            _c("br"),
            _vm._v(" "),
            _vm.isLoaded
              ? _c(
                  "span",
                  {
                    staticClass:
                      "block mt-1 text-xl font-semibold leading-8 lg:text-2xl",
                    staticStyle: { color: "#fb7178" }
                  },
                  [
                    _c("div", {
                      domProps: {
                        innerHTML: _vm._s(
                          _vm.$utils.formatMoney(
                            _vm.getTotalExpenses,
                            _vm.defaultCurrency
                          )
                        )
                      }
                    })
                  ]
                )
              : _vm._e()
          ]),
          _vm._v(" "),
          _c(
            "div",
            {
              staticClass:
                "col-span-3 p-6 border-t border-gray-200 border-solid lg:col-span-1"
            },
            [
              _c("span", { staticClass: "text-xs leading-5 lg:text-sm" }, [
                _vm._v(
                  "\n        " +
                    _vm._s(_vm.$t("dashboard.chart_info.net_income")) +
                    "\n      "
                )
              ]),
              _vm._v(" "),
              _c("br"),
              _vm._v(" "),
              _c(
                "span",
                {
                  staticClass:
                    "block mt-1 text-xl font-semibold leading-8 lg:text-2xl",
                  staticStyle: { color: "#13A196" }
                },
                [
                  _c("div", {
                    domProps: {
                      innerHTML: _vm._s(
                        _vm.$utils.formatMoney(
                          _vm.getNetProfit,
                          _vm.defaultCurrency
                        )
                      )
                    }
                  })
                ]
              )
            ]
          )
        ]
      )
    ]
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }