var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "header",
    {
      staticClass:
        "fixed top-0 left-0 z-40 flex items-center justify-between w-full px-4 py-3 md:h-16 md:px-8 bg-gradient-to-r from-primary-500 to-primary-400"
    },
    [
      _vm._m(0),
      _vm._v(" "),
      _c(
        "ul",
        { staticClass: "float-right h-8 m-0 list-none md:h-9" },
        [
          _c("global-search", {
            staticClass: "hidden float-left mr-2 md:block"
          }),
          _vm._v(" "),
          _c(
            "a",
            {
              staticClass:
                "flex float-left p-1 ml-3 overflow-visible text-sm text-black ease-linear bg-white border-0 rounded cursor-pointer md:hidden md:ml-0 hamburger hamburger--arrowturn",
              class: { "is-active": _vm.isSidebarOpen },
              attrs: { href: "#" },
              on: { click: _vm.toggleSidebar }
            },
            [_vm._m(1)]
          ),
          _vm._v(" "),
          _c(
            "li",
            { staticClass: "relative hidden float-left m-0 md:block" },
            [
              _c(
                "sw-dropdown",
                [
                  _c(
                    "a",
                    {
                      staticClass:
                        "inline-block text-sm text-black bg-white rounded-sm",
                      staticStyle: { padding: "6px" },
                      attrs: { slot: "activator", href: "#" },
                      slot: "activator"
                    },
                    [_c("plus-icon", { staticClass: "w-6 h-6" })],
                    1
                  ),
                  _vm._v(" "),
                  _c(
                    "sw-dropdown-item",
                    {
                      attrs: {
                        "tag-name": "router-link",
                        to: "/admin/invoices/create"
                      }
                    },
                    [
                      _c("document-text-icon", {
                        staticClass: "h-5 mr-2 text-gray-600"
                      }),
                      _vm._v(
                        "\n          " +
                          _vm._s(_vm.$t("invoices.new_invoice")) +
                          "\n        "
                      )
                    ],
                    1
                  ),
                  _vm._v(" "),
                  _c(
                    "sw-dropdown-item",
                    {
                      attrs: {
                        "tag-name": "router-link",
                        to: "/admin/estimates/create"
                      }
                    },
                    [
                      _c("document-icon", {
                        staticClass: "h-5 mr-2 text-gray-600"
                      }),
                      _vm._v(
                        "\n          " +
                          _vm._s(_vm.$t("estimates.new_estimate")) +
                          "\n        "
                      )
                    ],
                    1
                  ),
                  _vm._v(" "),
                  _c(
                    "sw-dropdown-item",
                    {
                      attrs: {
                        "tag-name": "router-link",
                        to: "/admin/customers/create"
                      }
                    },
                    [
                      _c("user-icon", {
                        staticClass: "h-5 mr-2 text-gray-600"
                      }),
                      _vm._v(
                        "\n          " +
                          _vm._s(_vm.$t("customers.new_customer")) +
                          "\n        "
                      )
                    ],
                    1
                  )
                ],
                1
              )
            ],
            1
          ),
          _vm._v(" "),
          _c(
            "li",
            { staticClass: "relative block float-left ml-2" },
            [
              _c(
                "sw-dropdown",
                [
                  _c(
                    "a",
                    {
                      staticClass:
                        "inline-block text-sm text-black bg-white rounded-sm avatar",
                      attrs: {
                        slot: "activator",
                        href: "#",
                        "data-toggle": "dropdown",
                        "aria-haspopup": "true",
                        "aria-expanded": "false"
                      },
                      slot: "activator"
                    },
                    [
                      _c("img", {
                        staticClass: "w-8 h-8 rounded-sm md:h-9 md:w-9",
                        attrs: { src: _vm.profilePicture, alt: "Avatar" }
                      })
                    ]
                  ),
                  _vm._v(" "),
                  _c(
                    "sw-dropdown-item",
                    {
                      attrs: {
                        "tag-name": "router-link",
                        to: "/admin/settings"
                      }
                    },
                    [
                      _c("cog-icon", {
                        staticClass: "w-4 h-4 mr-2 text-gray-600"
                      }),
                      _vm._v(
                        "\n          " +
                          _vm._s(_vm.$t("navigation.settings")) +
                          "\n        "
                      )
                    ],
                    1
                  ),
                  _vm._v(" "),
                  _c(
                    "sw-dropdown-item",
                    { on: { click: _vm.logout } },
                    [
                      _c("logout-icon", {
                        staticClass: "w-4 h-4 mr-2 text-gray-600"
                      }),
                      _vm._v(
                        "\n          " +
                          _vm._s(_vm.$t("navigation.logout")) +
                          "\n        "
                      )
                    ],
                    1
                  )
                ],
                1
              )
            ],
            1
          )
        ],
        1
      )
    ]
  )
}
var staticRenderFns = [
  function() {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c(
      "a",
      {
        staticClass:
          "float-none text-lg not-italic font-black tracking-wider text-white brand-main md:float-left font-base",
        attrs: { href: "/admin/dashboard" }
      },
      [
        _c("img", {
          staticClass: "hidden h-6 md:block",
          attrs: {
            id: "logo-white",
            src: "/assets/img/logo-white.png",
            alt: "Crater Logo"
          }
        }),
        _vm._v(" "),
        _c("img", {
          staticClass: "block h-8 md:hidden",
          attrs: {
            id: "logo-mobile",
            src: "/assets/img/crater-white-small.png",
            alt: "Crater Logo"
          }
        })
      ]
    )
  },
  function() {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c("div", { staticClass: "relative inline-block w-6 h-6" }, [
      _c("div", { staticClass: "block hamburger-inner top-1/2" })
    ])
  }
]
render._withStripped = true

export { render, staticRenderFns }