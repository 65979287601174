var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("div", { staticClass: "col-span-5 pr-0" }, [
    _vm.selectedCustomer
      ? _c(
          "div",
          {
            staticClass:
              "flex flex-col p-4 bg-white border border-gray-200 border-solid",
            staticStyle: { "min-height": "170px" }
          },
          [
            _c("div", { staticClass: "relative flex justify-between mb-1" }, [
              _c("label", { staticClass: "flex-1 font-medium" }, [
                _vm._v(_vm._s(_vm.selectedCustomer.name))
              ]),
              _vm._v(" "),
              _c(
                "a",
                {
                  staticClass:
                    "relative my-0 ml-0 mr-6 text-sm font-medium cursor-pointer text-primary-500",
                  on: {
                    click: function($event) {
                      $event.preventDefault()
                      return _vm.editCustomer($event)
                    }
                  }
                },
                [
                  _vm._v(
                    "\n        " + _vm._s(_vm.$t("general.edit")) + "\n      "
                  )
                ]
              ),
              _vm._v(" "),
              _c(
                "a",
                {
                  staticClass:
                    "relative my-0 ml-0 mr-6 text-sm font-medium cursor-pointer text-primary-500",
                  on: {
                    click: function($event) {
                      $event.preventDefault()
                      return _vm.resetSelectedCustomer($event)
                    }
                  }
                },
                [
                  _vm._v(
                    "\n        " +
                      _vm._s(_vm.$t("general.deselect")) +
                      "\n      "
                  )
                ]
              )
            ]),
            _vm._v(" "),
            _c("div", { staticClass: "grid grid-cols-2 gap-4 mt-1" }, [
              _vm.selectedCustomer.billing_address
                ? _c("div", [
                    _c("div", { staticClass: "flex flex-col" }, [
                      _c(
                        "label",
                        {
                          staticClass:
                            "mb-1 text-sm font-medium text-gray-500 uppercase whitespace-no-wrap"
                        },
                        [
                          _vm._v(
                            "\n            " +
                              _vm._s(_vm.$t("general.bill_to")) +
                              "\n          "
                          )
                        ]
                      ),
                      _vm._v(" "),
                      _c("div", { staticClass: "flex flex-col flex-1 p-0" }, [
                        _vm.selectedCustomer.billing_address.name
                          ? _c(
                              "label",
                              {
                                staticClass: "relative w-11/12 text-sm truncate"
                              },
                              [
                                _vm._v(
                                  "\n              " +
                                    _vm._s(
                                      _vm.selectedCustomer.billing_address.name
                                    ) +
                                    "\n            "
                                )
                              ]
                            )
                          : _vm._e(),
                        _vm._v(" "),
                        _vm.selectedCustomer.billing_address.address_street_1
                          ? _c(
                              "label",
                              {
                                staticClass: "relative w-11/12 text-sm truncate"
                              },
                              [
                                _vm._v(
                                  "\n              " +
                                    _vm._s(
                                      _vm.selectedCustomer.billing_address
                                        .address_street_1
                                    ) +
                                    "\n            "
                                )
                              ]
                            )
                          : _vm._e(),
                        _vm._v(" "),
                        _vm.selectedCustomer.billing_address.address_street_2
                          ? _c(
                              "label",
                              {
                                staticClass: "relative w-11/12 text-sm truncate"
                              },
                              [
                                _vm._v(
                                  "\n              " +
                                    _vm._s(
                                      _vm.selectedCustomer.billing_address
                                        .address_street_2
                                    ) +
                                    "\n            "
                                )
                              ]
                            )
                          : _vm._e(),
                        _vm._v(" "),
                        _vm.selectedCustomer.billing_address.city &&
                        _vm.selectedCustomer.billing_address.state
                          ? _c(
                              "label",
                              {
                                staticClass: "relative w-11/12 text-sm truncate"
                              },
                              [
                                _vm._v(
                                  "\n              " +
                                    _vm._s(
                                      _vm.selectedCustomer.billing_address.city
                                    ) +
                                    ",\n              " +
                                    _vm._s(
                                      _vm.selectedCustomer.billing_address.state
                                    ) +
                                    "\n              " +
                                    _vm._s(
                                      _vm.selectedCustomer.billing_address.zip
                                    ) +
                                    "\n            "
                                )
                              ]
                            )
                          : _vm._e(),
                        _vm._v(" "),
                        _vm.selectedCustomer.billing_address.country
                          ? _c(
                              "label",
                              {
                                staticClass: "relative w-11/12 text-sm truncate"
                              },
                              [
                                _vm._v(
                                  "\n              " +
                                    _vm._s(
                                      _vm.selectedCustomer.billing_address
                                        .country.name
                                    ) +
                                    "\n            "
                                )
                              ]
                            )
                          : _vm._e(),
                        _vm._v(" "),
                        _vm.selectedCustomer.billing_address.phone
                          ? _c(
                              "label",
                              {
                                staticClass: "relative w-11/12 text-sm truncate"
                              },
                              [
                                _vm._v(
                                  "\n              " +
                                    _vm._s(
                                      _vm.selectedCustomer.billing_address.phone
                                    ) +
                                    "\n            "
                                )
                              ]
                            )
                          : _vm._e()
                      ])
                    ])
                  ])
                : _vm._e(),
              _vm._v(" "),
              _vm.selectedCustomer.shipping_address
                ? _c("div", [
                    _c("div", { staticClass: "flex flex-col" }, [
                      _c(
                        "label",
                        {
                          staticClass:
                            "mb-1 text-sm font-medium text-gray-500 uppercase whitespace-no-wrap"
                        },
                        [
                          _vm._v(
                            "\n            " +
                              _vm._s(_vm.$t("general.ship_to")) +
                              "\n          "
                          )
                        ]
                      ),
                      _vm._v(" "),
                      _c("div", { staticClass: "flex flex-col flex-1 p-0" }, [
                        _vm.selectedCustomer.shipping_address.name
                          ? _c(
                              "label",
                              {
                                staticClass: "relative w-11/12 text-sm truncate"
                              },
                              [
                                _vm._v(
                                  "\n              " +
                                    _vm._s(
                                      _vm.selectedCustomer.shipping_address.name
                                    ) +
                                    "\n            "
                                )
                              ]
                            )
                          : _vm._e(),
                        _vm._v(" "),
                        _vm.selectedCustomer.shipping_address.address_street_1
                          ? _c(
                              "label",
                              {
                                staticClass: "relative w-11/12 text-sm truncate"
                              },
                              [
                                _vm._v(
                                  "\n              " +
                                    _vm._s(
                                      _vm.selectedCustomer.shipping_address
                                        .address_street_1
                                    ) +
                                    "\n            "
                                )
                              ]
                            )
                          : _vm._e(),
                        _vm._v(" "),
                        _vm.selectedCustomer.shipping_address.address_street_2
                          ? _c(
                              "label",
                              {
                                staticClass: "relative w-11/12 text-sm truncate"
                              },
                              [
                                _vm._v(
                                  "\n              " +
                                    _vm._s(
                                      _vm.selectedCustomer.shipping_address
                                        .address_street_2
                                    ) +
                                    "\n            "
                                )
                              ]
                            )
                          : _vm._e(),
                        _vm._v(" "),
                        _vm.selectedCustomer.shipping_address.city &&
                        _vm.selectedCustomer.shipping_address
                          ? _c(
                              "label",
                              {
                                staticClass: "relative w-11/12 text-sm truncate"
                              },
                              [
                                _vm._v(
                                  "\n              " +
                                    _vm._s(
                                      _vm.selectedCustomer.shipping_address.city
                                    ) +
                                    ",\n              " +
                                    _vm._s(
                                      _vm.selectedCustomer.shipping_address
                                        .state
                                    ) +
                                    "\n              " +
                                    _vm._s(
                                      _vm.selectedCustomer.shipping_address.zip
                                    ) +
                                    "\n            "
                                )
                              ]
                            )
                          : _vm._e(),
                        _vm._v(" "),
                        _vm.selectedCustomer.shipping_address.country
                          ? _c(
                              "label",
                              {
                                staticClass: "relative w-11/12 text-sm truncate"
                              },
                              [
                                _vm._v(
                                  "\n              " +
                                    _vm._s(
                                      _vm.selectedCustomer.shipping_address
                                        .country.name
                                    ) +
                                    "\n            "
                                )
                              ]
                            )
                          : _vm._e(),
                        _vm._v(" "),
                        _vm.selectedCustomer.shipping_address.phone
                          ? _c(
                              "label",
                              {
                                staticClass: "relative w-11/12 text-sm truncate"
                              },
                              [
                                _vm._v(
                                  "\n              " +
                                    _vm._s(
                                      _vm.selectedCustomer.shipping_address
                                        .phone
                                    ) +
                                    "\n            "
                                )
                              ]
                            )
                          : _vm._e()
                      ])
                    ])
                  ])
                : _vm._e()
            ])
          ]
        )
      : _c(
          "div",
          [
            _c(
              "sw-popup",
              {
                class: [
                  "p-0",
                  {
                    "border border-solid border-danger rounded":
                      _vm.valid.$error
                  }
                ]
              },
              [
                _c(
                  "div",
                  {
                    staticClass:
                      "relative flex justify-center px-0 py-16 bg-white border border-gray-200 border-solid rounded",
                    staticStyle: { "min-height": "170px" },
                    attrs: { slot: "activator" },
                    slot: "activator"
                  },
                  [
                    _c("user-icon", {
                      staticClass:
                        "flex justify-center w-10 h-10 p-2 mr-5 text-sm text-white bg-gray-200 rounded-full font-base"
                    }),
                    _vm._v(" "),
                    _c("div", { staticClass: "mt-1" }, [
                      _c("label", { staticClass: "text-lg" }, [
                        _vm._v(
                          "\n            " +
                            _vm._s(_vm.$t("customers.new_customer")) +
                            "\n            "
                        ),
                        _c("span", { staticClass: "text-danger" }, [
                          _vm._v(" * ")
                        ])
                      ]),
                      _vm._v(" "),
                      _vm.valid.$error && !_vm.valid.required
                        ? _c("p", { staticClass: "text-danger" }, [
                            _vm._v(
                              "\n            " +
                                _vm._s(_vm.$t("estimates.errors.required")) +
                                "\n          "
                            )
                          ])
                        : _vm._e()
                    ])
                  ],
                  1
                ),
                _vm._v(" "),
                _c("customer-select-popup", {
                  attrs: { "user-id": _vm.customerId, type: "estimate" }
                })
              ],
              1
            )
          ],
          1
        )
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }